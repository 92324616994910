import { Flip, Fade } from 'react-reveal';
import { tools, skills, info } from '../../../configs/Lists';
import { SkillCard, ToolCard } from '../Cards/Cards';
import { SmallList } from '../Lists/Lists';

export function Header() {
  return (
    <div className='about_header fade-in'>
      <Flip left cascade>
        <h3>About</h3>
      </Flip>

      <div className='description bottom-slide-in'>
        {' '}
        <code>{info.map((i) => i.desc)}</code>{' '}
      </div>
    </div>
  );
}

export function Skills() {
  return (
    <div className='about_skills slide-in'>
      <Flip left cascade>
        <h3>Skillset</h3>
      </Flip>
      <SmallList data={skills} Card={SkillCard} Anim={Fade} />
    </div>
  );
}

export function Tools() {
  return (
    <div className='about_tools slide-in'>
      <Flip left cascade>
        <h3>Tools</h3>
      </Flip>
      <SmallList data={tools} Card={ToolCard} />
    </div>
  );
}
