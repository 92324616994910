import { useMemo, useState } from 'react';
import './styles/App.scss';

import Menu from './components/Menu/Menu';
import Footer from './components/pages/Footer/Footer';
import Home from './components/pages/Home/Home';
import About from './components/pages/About/About';
import Projects from './components/pages/Projects/Projects';
import Roles from './components/pages/Roles/Roles';
import Ventures from './components/pages/Ventures/Ventures';
import Contact from './components/pages/Contact/Contact';

import useManifest from './components/utils/Hooks/useManifest';
import useTop from './components/utils/Hooks/useTop';
import useAnim from './components/utils/Hooks/useAnim';
import useLocalStorage from './components/utils/Hooks/useLocalStorage';

function App() {
  let siteOptions = JSON.parse(window.localStorage.getItem('siteOptions'));

  const defaultOptions = {
    theme: 'earth',
    firstVisit: true,
  };

  if (siteOptions == null) {
    siteOptions = defaultOptions;
  }

  const [options, setOptions] = useLocalStorage('siteOptions', siteOptions);

  const [theme, setTheme] = useState(options.theme);

  useManifest(theme);

  useMemo(() => {
    options.theme = theme;
    setOptions(options);
  }, [theme]);

  const [isTop, setIsTop] = useState(true);
  useTop(setIsTop);

  useAnim('fade-in');
  useAnim('bottom-slide-in');

  return (
    <div className={'App ' + theme}>
      <div className='screen fade-in'>
        <Menu theme={theme} setTheme={setTheme} isTop={isTop} />
        <div id='main' className='main'>
          <Home theme={theme} />
          <About />
          <Projects theme={theme} />
          <Roles theme={theme} />
          <Ventures />
          <Contact theme={theme} />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
