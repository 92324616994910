import '../../../styles/Projects.scss';
import Gallery from '../../utils/Populate/Gallery';
import Discography from '../../utils/Populate/Discography';

export default function Projects({ theme }) {
  return (
    <div id='projects' className='projects'>
      <Gallery />
      {theme === 'mars' ? <Discography /> : ''}
    </div>
  );
}
