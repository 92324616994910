import '../../../styles/About.scss';
import { Header, Skills, Tools } from '../../utils/Populate/About';

export default function About() {
  return (
    <div id='about' className='about'>
      <Header />
      <Skills />
      <Tools />
    </div>
  );
}
