import { MenuList } from '../Lists/Lists';
import { menuList, info } from '../../../configs/Lists';
import Themes from '../Themes/Themes';
import { CgMenuRight as Toggle } from 'react-icons/cg';
import Clock from '../Clock/Clock';

export default function Main({ theme, setTheme, handleClick, isTop }) {
  return (
    <div className={'menu ' + (isTop ? '' : ' not-top')}>
      <Brand data={info} theme={theme} />
      <Themes theme={theme} setTheme={setTheme} />
      <Clock />
      <MenuList data={menuList} />
      <Icon handleClick={handleClick} />
    </div>
  );
}

function Brand({ data, theme }) {
  return (
    <div className='brand'>
      {data.map((i) => (
        <a href='#home' key={i.id}>
          <img src={theme === 'mars' ? i.avatar2 : i.avatar} alt='' />
          <code className='name'>
            {theme === 'mars' ? i.alias : i.firstName + ' ' + i.lastName}
          </code>
        </a>
      ))}
    </div>
  );
}

function Icon({ handleClick }) {
  return (
    <div className='icon'>
      <Toggle onClick={() => handleClick()} />
    </div>
  );
}
