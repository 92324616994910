import '../../../styles/Footer.scss';

function Footer() {
  return (
    <div className='footer fade-in'>
      <code>
        <div className='footer_body'>
          Developed using{' '}
          <a href='https://reactjs.org/' target='_blank' rel='noreferrer'>
            React
          </a>{' '}
          and{' '}
          <a href='https://sass-lang.com/' target='_blank' rel='noreferrer'>
            Sass
          </a>{' '}
          <span className='deployed'>
            Deployed using{' '}
            <a
              href='https://firebase.google.com/docs/cli'
              target='_blank'
              rel='noreferrer'
            >
              Firebase CLI
            </a>{' '}
          </span>
        </div>
      </code>
    </div>
  );
}

export default Footer;
