import Typewriter from 'typewriter-effect';
import Flip from 'react-reveal/Flip';
import { titles } from '../../../configs/Lists';

function Titles() {
  return (
    <div className='titles'>
      <Flip left cascade>
        <code>and I'm</code>
      </Flip>
      <code>
        <Typewriter
          options={{
            strings: titles,
            autoStart: true,
            loop: true,
            delay: 'natural',
            pauseFor: 2000,
            deleteSpeed: 'natural',
          }}
        />
      </code>
    </div>
  );
}

export default Titles;
