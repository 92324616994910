import '../../../styles/Home.scss';
import Titles from '../../utils/Titles/Titles';
import Info from '../../utils/Info/Info';

export default function Home({ theme }) {
  return (
    <div id='home' className='home section'>
      <Info theme={theme} />
      <Titles  className='fade-in'/>
    </div>
  );
}
