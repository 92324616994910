import { useState } from 'react';
import { themes } from '../../../configs/Lists';

function Themes({ theme, setTheme }) {
  const [themeMenuOpen, setThemeMenuOpen] = useState(false);
  function handleThemeMenu() {
    setThemeMenuOpen(!themeMenuOpen);
  }
  function handleTheme(theme) {
    setTheme(theme);
    setThemeMenuOpen(false);
  }

  return (
    <div className='themesContainer'>
      <code onClick={() => handleThemeMenu()}>
        {' '}
        <span>{theme}</span>{' '}
      </code>
      <ul className={'themes ' + (themeMenuOpen && 'active')}>
        {themes.map((i) => (
          <li key={i.id} onClick={() => handleTheme(i.theme)}>
            {i.theme}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Themes;
