import '../../../styles/Roles.scss';
import Flip from 'react-reveal/Flip';
import { CurrentRoles, PastRoles } from '../../utils/Populate/Roles';

export default function Roles({ theme }) {
  return (
    <div id='timeline' className='timeline'>
      <Flip left cascade>
        <h3>Roles</h3>
      </Flip>
      <CurrentRoles theme={theme} />
      <PastRoles />
    </div>
  );
}
