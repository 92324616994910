import { Flip } from 'react-reveal';

export function SkillCard({ item }) {
  return (
    <div className='card'>
      <a href={item.url} target='_blank' rel='noreferrer'>
        {item.skill}
      </a>
      <svg viewBox='0 0 32 31' preserveAspectRatio='none' role='img'>
        <path d={item.icon} />
      </svg>
      <Flip left cascade>
        <span>{item.extension}</span>
      </Flip>
    </div>
  );
}

export function ToolCard({ item }) {
  return (
    <div className='card'>
      <svg viewBox='0 0 32 31' preserveAspectRatio='none' role='img'>
        <path d={item.icon} />
      </svg>
      <a href={item.url} target='_blank' rel='noreferrer'>
        <code>{item.title}</code>
      </a>
    </div>
  );
}

export function GalleryCard({ item }) {
  return (
    <div className='card'>
      <img src={item.img} alt={item.title} />
      <span>
        <code>{item.title}</code>
      </span>
    </div>
  );
}

export function MusicCard({ item }) {
  return (
    <div className='card'>
      <img src={item.img} alt='' />
      <a href={item.url} target='_blank' rel='noreferrer'>
        <span>
          {' '}
          <code> {item.title} </code>{' '}
        </span>
      </a>
    </div>
  );
}

export function RoleCard({ item, theme }) {
  return (
    <ul className='card fade-in'>
      <li>
        <code>
          <Flip left cascade>
            <span>{theme === 'mars' ? item.boss : item.title}</span>
          </Flip>
        </code>
      </li>
      <a href={item.url} target='_blank' rel='noreferrer'>
        <li>{item.organization}</li>
      </a>
    </ul>
  );
}
export function DatedRoleCard({ item }) {
  return (
    <ul className='card'>
      <li>{item.dates}</li>
      <li>
        <code>
          <Flip left cascade>
            <span>{item.title}</span>
          </Flip>
        </code>
      </li>
      <a href={item.url} target='_blank' rel='noreferrer'>
        <li>{item.organization}</li>
      </a>
    </ul>
  );
}
export function VentureCard({ item }) {
  return (
    <div className='card bottom-slide-in'>
      <img className='bg' src={item.bg} alt='' />
      <li>
        <a href={item.url} target='_blank' rel='noreferrer'>
          <Flip left cascade>
            <img className='logo' src={item.logo} alt='' />
          </Flip>
        </a>
      </li>
    </div>
  );
}
export function SocialCard({ item }) {
  return (
    <li className='socials_list_card'>
      <svg viewBox='-5 0 28 28' preserveAspectRatio='none' role='img'>
        <path d={item.icon} />
      </svg>
      <a href={item.url}>
        <code>{item.name}</code>
      </a>
    </li>
  );
}
