import '../../styles/Pages.scss';
import { menuList } from '../../configs/Lists';
import { PagesList } from '../utils/Lists/Lists';

export default function Pages({ theme , active, handleClick }) {
  return (
    <div id='pages' className={'pages ' + (active && 'active ')+' '+theme}>
      <PagesList data={menuList} handleClick={handleClick} />
    </div>
  );
}
