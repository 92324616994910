import Flip from 'react-reveal/Flip';
import { ventures } from '../../../configs/Lists';
import { VentureCard } from '../../utils/Cards/Cards';
import { VenturesList } from '../../utils/Lists/Lists';

export default function PopulateVentures() {
  return (
    <div id='ventures' className='ventures'>
      <Flip left cascade>
        <h3>Ventures</h3>
      </Flip>
      <VenturesList data={ventures} Card={VentureCard} />
    </div>
  );
}
