import { info } from '../../../configs/Lists';
import Flip from 'react-reveal/Flip';

function Info({ theme }) {
  return (
    <div className='idContainer'>
      <Flip left cascade>
        {theme === 'mars' ? <h2>Eyy</h2> : <h2>Hi</h2>}
      </Flip>
      {info.map((i) => (
        <div className='id' key={i.id}>
          <Flip left>
            <img
              className='pic fade-in'
              src={theme === 'mars' ? i.avatar2 : i.avatar}
              alt={i.firstName}
            />
          </Flip>
          {theme === 'mars' ? (
            <h3 className='fade-in'>
              It's <span className='name '>{i.alias}</span>
            </h3>
          ) : (
            <h3 className='fade-in'>
              My name's <span className='name '>{i.firstName}</span>
            </h3>
          )}
        </div>
      ))}
    </div>
  );
}

export default Info;
