import { Flip } from 'react-reveal';

export function SmallList({ data, Card }) {
  return (
    <ul className='list'>
      {data.map((i) => (
        <li key={i.id}>
          <Flip left>
            <Card item={i} />
          </Flip>
        </li>
      ))}
    </ul>
  );
}
export function GalleryList({ data, Card }) {
  return (
    <ul>
      {data.map((i) => (
        <li key={i.id}>
          <a href={i.url} target='_blank' rel='noreferrer'>
            <Flip left>
              <Card item={i} />
            </Flip>
          </a>
        </li>
      ))}
    </ul>
  );
}

export function MusicList({ data, Card }) {
  return (
    <ul>
      {data.map((i) => (
        <Flip left key={i.id}>
          <li>
            <Card item={i} />
          </li>
        </Flip>
      ))}
    </ul>
  );
}
export function VenturesList({ data, Card }) {
  return (
    <ul className='list fade-in'>
      {data.map((i) => (
        <Card item={i} key={i.id} />
      ))}
    </ul>
  );
}
export function SocialsList({ data, Card }) {
  return (
    <ul className='socials_list'>
      {data.map((i) => (
        <Flip left key={i.id}>
          <Card item={i} />
        </Flip>
      ))}
    </ul>
  );
}
export function MenuList({ data }) {
  return (
    <ul className='list'>
      {data.map((i) => (
        <li key={i.id}>
          <a href={i.url}>{i.title}</a>
        </li>
      ))}
    </ul>
  );
}
export function PagesList({ data, handleClick }) {
  return (
    <ul>
      {data.map((i) => (
        <li key={i.id}>
          <a href={i.url} onClick={() => handleClick()}>
            <h3>{i.title}</h3>
          </a>
        </li>
      ))}
    </ul>
  );
}
