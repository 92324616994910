import { useState } from 'react';
import '../../styles/Menu.scss';
import Pages from './Pages';
import Main from '../utils/Populate/Menu';

function Menu({ theme, setTheme, isTop }) {
  const [menuOpen, setMenuOpen] = useState(false);
  function handleClick() {
    setMenuOpen(!menuOpen);
  }
  return (
    <div>
      <Main theme={theme} setTheme={setTheme} handleClick={handleClick} isTop={isTop}  />
      <Pages theme={theme} active={menuOpen} handleClick={handleClick} />
    </div>
  );
}

export default Menu;
