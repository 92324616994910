import { useState, useEffect } from 'react';
export default function Clock() {
  const [date, setDate] = useState(new Date());

  function refreshClock() {
    setDate(new Date());
  }
  useEffect(() => {
    const timerId = setInterval(refreshClock, 15000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);
  return (
    <div className='clockContainer'>
      <span>
        {date.toLocaleTimeString([], {
          hour12: false,
          hour: '2-digit',
          minute: '2-digit',
        })}
      </span>
    </div>
  );
}