import '../../../styles/Contact.scss';
import { Socials } from '../../utils/Populate/Socials';
import { Body } from '../../utils/Populate/Contact';

export default function Contact({ theme }) {
  return (
    <div id='contact' className='contact'>
      <Body theme={theme}/>
      {theme === 'mars' ? (
        <Socials />
      ) : (
        ''
      )}
    </div>
  );
}

