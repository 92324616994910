import { useEffect } from 'react';

function useTop(setIsTop) {
  useEffect(() => {
    const _home = document.querySelector('.home');

    const _homeOptions = {
      rootMargin: '-200px 0px 0px 0px',
    };

    const _homeObserver = new IntersectionObserver(function (
      entries,
      _homeObserver
    ) {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          setIsTop(false);
        } else {
          setIsTop(true);
        }
      });
    },
    _homeOptions);
    _homeObserver.observe(_home);
  });
}

export default useTop;
