import Flip from 'react-reveal/Flip';
import { info } from '../../../configs/Lists';

export function Body({ theme }) {
  return (
    <div className='contact_body fade-in'>
      <Flip left cascade>
        {theme !== 'mars' ? (
          <h3>Want a portfolio like this?</h3>
        ) : (
          <h3>Contact</h3>
        )}
      </Flip>
      email me at
      {info.map((i) => (
        <div className='emails' key={i.id}>
          {theme === 'mars' ? (
            <a href={i.email2}>
              <code>
                <Flip left cascade>
                  <span>{i.email2.slice(7)}</span>
                </Flip>
              </code>{' '}
            </a>
          ) : (
            <a href={i.email}>
              <code>
                <Flip left cascade>
                  <span>{i.email.slice(7)}</span>
                </Flip>
              </code>{' '}
            </a>
          )}
        </div>
      ))}
    </div>
  );
}
