import { useEffect } from 'react';
import Manifest from '../Manifest/Manifest';

function useManifest(theme) {
  useEffect(() => {
    Manifest(theme);
  }, [theme]);
  return;
}

export default useManifest;
