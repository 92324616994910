export default function Manifest(theme) {
  let color;
  function _chooseColor(selected) {
    switch (selected) {
      case 'core':
        color = '#000';
        break;
      case 'mars':
        color = 'red';
        break;
      case 'pluto':
        color = 'blue';
        break;

      default:
        color = '#f1f1f1';
        break;
    }
  }
  
  _chooseColor(theme);
  const _color = color;
  const _manifestPlaceholder = document.querySelector('#manifest');
  const _manifest = {
    id: '.',
    name: 'Abdullah Monaqil',
    short_name: "Abdullah's portfolio",
    description: "Abdullah Monaqil's portfolio made using React and Sass",
    theme_color: _color,
    background_color: '#f1f1f1',
    icons: [
      {
        src: 'https://portfolio.broccolibusiness.com/favicon.ico',
        sizes: '64x64 32x32 24x24 16x16',
        type: 'image/x-icon',
      },
      {
        src: 'https://portfolio.broccolibusiness.com/logo192.png',
        type: 'image/png',
        sizes: '192x192',
      },
      {
        src: 'https://portfolio.broccolibusiness.com/logo512.png',
        type: 'image/png',
        sizes: '512x512',
      },
    ],
    start_url: 'https://portfolio.broccolibusiness.com/',
    display: 'standalone',
    author: 'DBL Z',
    manifest_version: 1,
    version: '1.0.0',
    default_locale: 'en',
  };
  const manifest = JSON.stringify(_manifest);
  const blob = new Blob([manifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  _manifestPlaceholder.removeAttribute('href');
  _manifestPlaceholder.setAttribute('href', manifestURL);
  return;
}
