import { roles } from '../../../configs/Lists';
import {DatedRoleCard, RoleCard } from '../Cards/Cards';

export function CurrentRoles({ theme }) {
  return (
    <div className='currentRoles'>
      {roles.map((i) =>
        i.current ? <RoleCard key={i.id} item={i} theme={theme} /> : ''
      )}
    </div>
  );
}
export function PastRoles() {
  return (
    <div className='pastRoles fade-in'>
      Past Roles:
      {roles.map((i) =>
        !i.current ? <DatedRoleCard key={i.id} item={i} /> : ''
      )}
    </div>
  );
}
