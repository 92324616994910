import { gallery } from "../../../configs/Lists";
import { GalleryList } from "../Lists/Lists";
import { GalleryCard } from "../Cards/Cards";
import Flip from 'react-reveal/Flip';


export default function Gallery() {
  return (
    <div className='gallery fade-in'>
      <Flip left cascade>
        <h3>Showcase</h3>
      </Flip>
      here's a small showcase of some of my projects
      <GalleryList data={gallery} Card={GalleryCard} />
      
    </div>
  );
}
