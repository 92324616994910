import { SocialsList } from '../Lists/Lists';
import { socials } from '../../../configs/Lists';
import { SocialCard } from '../Cards/Cards';

export function Socials() {
  return (
    <div className='socials'>
      <SocialsList data={socials} Card={SocialCard} />
    </div>
  );
}
