import { MusicCard } from '../Cards/Cards';
import {MusicList} from '../Lists/Lists'
import { art } from '../../../configs/Lists';
import { Flip } from 'react-reveal';


export default function Discography() {
  return (
    <div className='discography'>
      <Flip left cascade>
        <h3>Discography</h3>
      </Flip>
      Check out my music
      <MusicList data={art} Card={MusicCard}/>
    </div>
  );
}

